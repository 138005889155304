import type { GetServerSidePropsContext, NextPage } from "next"
import callDeeplinkAPI from "@utils/callDeeplinkAPI"
import NUMBERS from "@helpers/constants/numbers"
import { useContext, useEffect, useLayoutEffect } from "react"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"
import { hexalSubThemeConfig } from "@utils/hexalSubThemeUtil"
import Flexible from "../components/templates/flexible"
import { IFlexibleProps } from "../components/templates/flexible/_flexible.interface"
import { getAllFleixbleBlocksData, getFlexiblePageBlocks } from "../helpers/flexibleApi"
import Loader from "@atoms/loader"
import {
  LazyLoadingComponentType,
  useLazyLoadingComponents,
} from "@helpers/hooks/lazyLoadingComponents/useLazyLoadingComponents"
import { getDomainDataFromMiddleware } from "@utils/session-util/sessionHandler"
import ApplicationContext from "@utils/application-context/applicationContext"
import { IHeaderOptions } from "@utils/application-context/applicationData"
import useMicrositeConfig from "@helpers/hooks/useMicrositeConfig"
import { useRouter } from "next/router"
import { PIN_PROTECTION } from "@helpers/constants/generic"
import logMessage from "@utils/fs/logger"
import { LOG_TYPES } from "@helpers/constants/logger"
import * as Sentry from "@sentry/nextjs"

declare global {
  var session: any
}

interface IHome extends IFlexibleProps {
  nid: any
  pathAlias: string
  pageTheme: any
  targetId: number
  paywall: boolean
  paywallNotice: boolean
  paywallRoles: string
  paywallText: string
  children: React.ReactElement<typeof Flexible>
  mlrID: string
  headerConfiguration: IHeaderOptions
  micrositeConfig?: any
  isPinProtectionEnabled?: string | boolean
  pinProtectionFallbackPageUrl?: string
}

const Home: NextPage<IHome> = (props: IHome) => {
  const {
    pageBlocks = [],
    nid,
    pathAlias,
    isHomepage = false,
    targetId,
    paywall,
    paywallNotice,
    paywallRoles,
    paywallText,
    pageTheme,
    headerConfiguration,
    micrositeConfig,
    isPinProtectionEnabled,
    pinProtectionFallbackPageUrl,
  } = props

  const applicationConfigContext = useContext(ApplicationContext)
  const router = useRouter()
  if (headerConfiguration) {
    applicationConfigContext.applicationConfiguration.headerConfiguration = headerConfiguration
  }

  useEffect(() => {
    if (Object.keys(applicationConfigContext?.applicationConfiguration).length) {
      applicationConfigContext.applicationConfiguration.mlrID = ""
      if (props?.mlrID) {
        applicationConfigContext.applicationConfiguration.mlrID = props?.mlrID
      }
    }
  }, [props?.mlrID])

  const { fetchMicrositePageConfigData } = useMicrositeConfig(applicationConfigContext)


  useEffect(() => {
    if (applicationConfigContext?.applicationConfiguration && micrositeConfig?.isMicrositeEnabled) {
      const currentComps = applicationConfigContext?.applicationComponentsData?.appComponents
      const currentItem = currentComps?.find((data: any) => data.pageAlias === "")
      if (!currentItem) {
        fetchMicrositePageConfigData("", micrositeConfig?.pageConfig)
      }
    }
  }, [router?.asPath, applicationConfigContext])

  const { applicationTheme, updateApplicationTheme } = useContext(ApplicationThemeContext)
  const componentBlocks: LazyLoadingComponentType = {
    pageBlocks,
    nid,
    pathAlias,
    isHomepage,
    targetId,
    paywall,
    paywallNotice,
    paywallRoles,
    paywallText,
  }

  const { loading, components, isPaywall3Available, iterateBlocksForPage } =
    useLazyLoadingComponents(componentBlocks, props)

  useLayoutEffect(() => {
    const getPageComponents = async () => await iterateBlocksForPage()
    if (pageBlocks.length > 0 || paywall) {
      getPageComponents()
    }
  }, [pageBlocks])

  let pinProtectionKey = sessionStorage.getItem("pin_protection_for")
  pinProtectionKey = pinProtectionKey ? JSON.parse(pinProtectionKey) : pinProtectionKey

  useLayoutEffect(() => {
    if (
      (isPinProtectionEnabled === PIN_PROTECTION.PATIENT &&
        !(pinProtectionKey === PIN_PROTECTION.PIN_PROTECTION_FOR_PATIENT)) ||
      (isPinProtectionEnabled === PIN_PROTECTION.HCP &&
        !(pinProtectionKey === PIN_PROTECTION.PIN_PROTECTION_FOR_HCP))
    ) {
      sessionStorage.removeItem("pin_protection_for")
      if (pinProtectionFallbackPageUrl !== "/") {
        router.replace(pinProtectionFallbackPageUrl + "#pin-protection")
      }
    }
  }, [])

  useEffect(() => {
    if (pageTheme) {
      // @ts-ignore
      const pageLevelTheme = hexalSubThemeConfig?.[pageTheme]
      updateApplicationTheme(applicationTheme, pageLevelTheme)
    } else {
      updateApplicationTheme(applicationTheme, "")
    }
  }, [pageTheme])

  return (
    <>
      {/* <div>Componnet Count: {components?.blocks && components?.blocks?.length}</div> */}
      <Flexible
        isPaywall3Available={isPaywall3Available}
        {...components}
        blocks={isPinProtectionEnabled && !pinProtectionKey ? [] : components?.blocks}
      />
      <Loader display={loading} />
    </>
  )
}

const _getPageProps = async (
  apiUrl: string,
  refererUrl?: string,
  isDeepLink?: boolean,
  serverData?: any,
  req?: any,
) => {
  const {
    pageBlocks,
    seoData,
    nid,
    targetid,
    is404,
    paywall,
    paywallText,
    mlrID,
    paywallNotice,
    paywallRoles,
    pageTheme,
    isiBannerDetails,
    headerConfiguration,
    bgImage,
    bgMobImage,
    bgImageOpacity,
    bgMobImageOpacity,
    micrositeConfig,
    isPinProtectionEnabled,
    pinProtectionFallbackPageUrl,
    referenceTextData,
  }: any = await getFlexiblePageBlocks(apiUrl, undefined, undefined, serverData)

  /**
   * server side rendered components
   * Currently assuming 3 component will load in server (0, 1, 2)
   * rest all will load in client side
   */
  const serverComponentLimit = parseInt(process.env.DEFAULT_SERVER_COMPONENT_TO_LOAD || "")
  const serverComponentChunkSize: number = Number.isInteger(serverComponentLimit)
    ? serverComponentLimit
    : 3
  let blocks = []
  if (pageBlocks && pageBlocks.length) {
    blocks = await Promise.all(
      pageBlocks.map(async (block: any, index: number) => {
        if (index < serverComponentChunkSize) {
          let detailedBlock = block
          detailedBlock = await getAllFleixbleBlocksData(
            [detailedBlock],
            nid,
            targetid,
            paywallText,
            serverData,
          )
          return detailedBlock?.[0]
        }

        return block
      }),
    )
  }

  if (!pageBlocks?.length) {
    const msg = JSON.stringify(serverData)
    logMessage(true, LOG_TYPES.ERROR, 200, apiUrl, "[Error] Home page layout data not found! " + msg, serverData?.cmsUrl)
    Sentry.captureException("[Error] Home page layout data not found! " + msg)
  }


  return {
    props: {
      pageBlocks: blocks,
      isHomepage: true,
      seoData,
      nid: nid ?? "",
      paywall,
      paywallText,
      mlrID,
      paywallNotice: paywallNotice || false,
      paywallRoles: paywallRoles || "",
      pageTheme,
      isDeepLink: isDeepLink ?? false,
      refererUrl,
      isiBannerDetails: isiBannerDetails ?? null,
      headerConfiguration,
      bgImage,
      bgMobImage,
      bgImageOpacity,
      bgMobImageOpacity,
      micrositeConfig,
      pageAlias: "",
      isPinProtectionEnabled,
      pinProtectionFallbackPageUrl,
      header1: req?.headers,
      referenceTextData: referenceTextData ?? null
    },
    notFound: is404,
  }
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { query, res, req } = context
  const refererUrl = req?.headers?.referer ?? ""
  res?.setHeader("Cache-Control", "public, s-maxage=300, stale-while-revalidate=1000")

  const {
    feDomainData,
    domainData,
    domainLang,
    token: cmsToken,
  } = getDomainDataFromMiddleware(context)

  const cookieName = context?.res?.getHeader("domain-application-id") as string

  const serverData: any = {
    cmsUrl: domainData,
    token: cmsToken,
    language: domainLang,
    session: context?.req?.cookies[cookieName] ?? null,
  }

  const token = query?.token as string
  const formattedPairs: string[] = []
  Object.entries(query).forEach(([key, value]) => {
    const pairStr = `${key}=${value}`
    formattedPairs.push(pairStr)
  })
  const formatedQuery = formattedPairs ? formattedPairs.join("&") : ""
  let apiUrl = "/jsonapi/page/<lang>?_format=json"
  apiUrl = formatedQuery ? `${apiUrl}&${formatedQuery}` : apiUrl

  if (token) {
    const {
      pageBlocks,
      seoData,
      nid,
      is404,
      targetid,
      paywall,
      paywallText,
      paywallNotice,
      paywallRoles,
      pageTheme,
      renderHcpModalPopUp,
      isDeepLink,
      micrositeConfig,
      referenceTextData,
    } = await callDeeplinkAPI(token, serverData)

    const blocksCount = pageBlocks?.length
    const isTokenInvalid = blocksCount === NUMBERS.ZERO

    if (isTokenInvalid) {
      return await _getPageProps(apiUrl, refererUrl, isDeepLink, serverData)
    }

    if (!blocksCount) {
      const msg = JSON.stringify(serverData)
      logMessage(true, LOG_TYPES.ERROR, 200, apiUrl, "[Error] Home page layout data not found! " + msg, serverData?.cmsUrl)
      Sentry.captureException("[Error] Home page layout data not found! " + msg)
    }

    return {
      props: {
        pageBlocks,
        isHomepage: true,
        seoData,
        nid: nid ?? "",
        targetid,
        paywall,
        paywallText,
        paywallNotice: paywallNotice || false,
        paywallRoles: paywallRoles || "",
        pageTheme,
        refererUrl: refererUrl ?? "",
        renderHcpModalPopUp: renderHcpModalPopUp ?? null,
        isDeepLink: isDeepLink ?? false,
        micrositeConfig,
        pageAlias: "",
        referenceTextData: referenceTextData ?? null
      },
      notFound: is404,
    }
  }

  if (context.req.cookies[cookieName]) {
    // global.session = context.req.cookies[cookieName]
  } else if (!global.session) {
    global.session = undefined
  }

  return await _getPageProps(apiUrl, refererUrl, false, serverData, req)
}

export default Home
